import { memo } from 'react';
import { FormDatePicker } from '@/shared/inputs/form-date-picker';
import { UIButton } from '@/shared/ui/ui-button';
import { UIFormLabel } from '@/shared/ui/ui-form-label';
import { UISection } from '@/shared/ui/ui-section';
import { FormControl, Stack, Typography } from '@mui/material';
import { FormTextInput } from '@/shared/inputs/form-text-input';
import { Link } from 'react-router-dom';
import { validateDateValues } from '@/pages/report-trips-page/scripts';
import { Dayjs } from 'dayjs';
import _ from 'lodash';

import { useTranslation } from 'react-i18next';
import { useReportsContext } from '../context';

export const TopSectionComponent = memo(
  () => {
    const { t } = useTranslation();
    const {
      StackComponent,
      form,
      title,
      onClickPostReport,
      isRewardReport,
      isPosted,
      isDeclineReportDisabled,
      isTravelReport,
      isReportEditLoading,
      isReportPostLoading,
      isYandex,
    } = useReportsContext();
    const { control, watch, getValues, setValue } = form;

    const dateTo = watch('dateTo');
    const dateFrom = watch('dateFrom');

    return (
      <UISection>
        <Stack spacing={4}>
          <StackComponent alignItems='center' spacing={1.5}>
            <Link to='/customer/documents?activeTab=0'>
              <UIButton color='inherit'>{t('back')}</UIButton>
            </Link>

            <Typography variant='h5'>{title}</Typography>
          </StackComponent>

          <StackComponent justifyContent='space-between'>
            <UIButton
              disabled={isPosted || isReportPostLoading}
              loading={isReportEditLoading}
              type='submit'
            >
              {t('save')}
            </UIButton>

            <StackComponent spacing={1.5}>
              <UIButton
                disabled={isPosted || isReportEditLoading}
                loading={isReportPostLoading}
                onClick={() => onClickPostReport(true, true)}
              >
                {t('customer_trips_page.run_report')}
              </UIButton>
              <UIButton
                color='inherit'
                disabled={isDeclineReportDisabled}
                onClick={() => onClickPostReport(false)}
              >
                {t('customer_trips_page.cancel_running')}
              </UIButton>
            </StackComponent>
          </StackComponent>

          <StackComponent spacing={2}>
            <StackComponent direction='column' spacing={1.5}>
              <FormControl required={!isPosted} fullWidth>
                <UIFormLabel>{t('customer_trips_page.period_from')}</UIFormLabel>
                <FormDatePicker
                  control={control}
                  name='dateFrom'
                  maxDate={dateTo}
                  onChange={(value) => {
                    const dateTo = getValues('dateTo');
                    if (
                      value?.isValid() &&
                      value?.year().toString().length >= 4 &&
                      dateTo?.isBefore(value)
                    ) {
                      setValue('dateTo', value);
                    }
                  }}
                  rules={{
                    required: !isPosted,
                    validate: (value) => {
                      const currentDate = value as Dayjs;
                      return validateDateValues(currentDate, t);
                    },
                  }}
                  slotProps={{
                    textField: {
                      required: !isPosted,
                      disabled: isPosted,
                    },
                  }}
                  readOnly={isPosted}
                />
              </FormControl>

              <FormControl required={!isPosted} fullWidth>
                <UIFormLabel>{t('customer_trips_page.period_to')}</UIFormLabel>
                <FormDatePicker
                  control={control}
                  name='dateTo'
                  minDate={dateFrom}
                  onChange={(value) => {
                    const dateFrom = getValues('dateFrom');
                    if (
                      value?.isValid() &&
                      value?.year().toString().length >= 4 &&
                      dateFrom?.isAfter(value)
                    ) {
                      setValue('dateFrom', value);
                    }
                  }}
                  rules={{
                    required: !isPosted,
                    validate: (value) => {
                      const currentDate = value as Dayjs;
                      return validateDateValues(currentDate, t);
                    },
                  }}
                  slotProps={{
                    textField: {
                      required: !isPosted,
                      disabled: isPosted,
                    },
                  }}
                  readOnly={isPosted}
                />
              </FormControl>
            </StackComponent>

            {isYandex && !isTravelReport && (
              <StackComponent direction='column' spacing={1.5}>
                <FormControl required={!isPosted} fullWidth>
                  <UIFormLabel>{t('customer_trips_page.contract_number')}</UIFormLabel>
                  <FormTextInput
                    control={control}
                    name='contractNumber'
                    placeholder={t('customer_trips_page.contract_number')}
                    rules={{ required: !isPosted }}
                    disabled={isPosted}
                  />
                </FormControl>

                <FormControl required={!isPosted} fullWidth>
                  <UIFormLabel>{t('customer_trips_page.contract_date')}</UIFormLabel>
                  <FormDatePicker
                    control={control}
                    name='contractDate'
                    rules={{
                      required: !isPosted,
                      validate: (value) => {
                        const currentDate = value as Dayjs;
                        return validateDateValues(currentDate, t);
                      },
                    }}
                    slotProps={{
                      textField: {
                        required: !isPosted,
                        disabled: isPosted,
                      },
                    }}
                    readOnly={isPosted}
                  />
                </FormControl>
              </StackComponent>
            )}

            {isYandex && !isRewardReport && (
              <StackComponent direction='column' spacing={1.5}>
                <FormControl required={!isPosted} fullWidth>
                  <UIFormLabel>{t('customer_trips_page.aggregator_percent')}</UIFormLabel>
                  <FormTextInput
                    control={control}
                    name='aggregatorPercent'
                    placeholder={t('customer_trips_page.aggregator_percent')}
                    regExp={/^([0-9]|[1-9][0-9]|100)$/}
                    rules={{ required: !isPosted }}
                    disabled={isPosted}
                  />
                </FormControl>

                <FormControl required={!isPosted} fullWidth>
                  <UIFormLabel>{t('customer_trips_page.taxi_fleet_percent')}</UIFormLabel>
                  <FormTextInput
                    control={control}
                    name='taxiPercent'
                    placeholder={t('customer_trips_page.taxi_fleet_percent')}
                    regExp={/^([0-9]|[1-9][0-9]|100)$/}
                    rules={{ required: !isPosted }}
                    disabled={isPosted}
                  />
                </FormControl>
              </StackComponent>
            )}
          </StackComponent>
        </Stack>
      </UISection>
    );
  },
  (prev, next) => _.isEqual(prev, next)
);
