import { FC, useContext, useEffect, useRef, useState } from 'react';
import { Box, Container, Stack, Typography } from '@mui/material';
import { dataURItoBlob, handleError } from '@/app/helpers';
import {
  useFinishSelfEmployedRegistrationMutation,
  useLazyCheckSelfEmployedStatusQuery,
  useLazyGetSelfEmployedCertificateQuery,
  useRegisterSelfEmployedMutation,
} from '@/entities/auth/api';
import { UIAlert } from '@/shared/ui/ui-alert';
import { UIButton } from '@/shared/ui/ui-button';
import { UILoader } from '@/shared/ui/ui-loader';
import { EmployeeRegistrationContext } from '../lib/employee-registration-context';
import Cookies from 'js-cookie';
import { SmsConfirmModal } from '@/shared/modals/sms-confirm-modal';
import { useSendConfirmationCodeMutation } from '@/app/api';

import { useTranslation } from 'react-i18next';
import { Colors } from '@/app/constants/colors';
import { UIStepperRef } from '@/shared/ui/ui-stepper/types';
import { SmsConfirmModalRef } from '@/shared/modals/sms-confirm-modal/types';
import { InvitationInfoResponse } from '@/app/api/model';

interface Props {
  stepperRef: UIStepperRef | null;
}

export const SelfEmplStatus: FC<Props> = ({ stepperRef }) => {
  const { t } = useTranslation();
  const { userData } = useContext(EmployeeRegistrationContext);

  const [isSmsCheckLoading, setIsSmsCheckLoading] = useState(false);
  const [isSmsLoading, setIsSmsLoading] = useState(false);

  const [checkSelfEmployedStatus, { isLoading, data }] = useLazyCheckSelfEmployedStatusQuery();
  const [getCertificate, { isLoading: isCertLoading, data: cert }] =
    useLazyGetSelfEmployedCertificateQuery();
  const [registerSelfEmployed, { isLoading: isRegLoading }] = useRegisterSelfEmployedMutation();
  const [sendSms] = useSendConfirmationCodeMutation();
  const [finishRegistration, { isLoading: isFinishLoading }] =
    useFinishSelfEmployedRegistrationMutation();

  const smsModalRef = useRef<SmsConfirmModalRef>(null);

  useEffect(() => {
    checkStatusHandler();
  }, []);

  const checkStatusHandler = async () => {
    if (!userData) return;
    try {
      const { selfEmployed } = await checkSelfEmployedStatus({
        p: userData.pinfl,
      }).unwrap();
      if (!selfEmployed) {
        if (!userData.hasSmsCode) {
          setIsSmsLoading(true);
          await sendSms({
            phone: userData.phone,
            pinfl: +userData.pinfl,
          }).unwrap();
          await smsModalRef.current?.init(userData.phone, checkSmsHandler);
          setIsSmsLoading(false);
          smsModalRef.current?.close();
        } else await registerAndCheck(userData);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsSmsLoading(false);
    }
  };

  const onAcceptHandler = async () => {
    const token = Cookies.get('token');
    if (!userData || !token) return;
    try {
      await finishRegistration({
        invitationCode: userData.invitationCode,
      }).unwrap();
      stepperRef?.next();
    } catch (error) {
      handleError(error);
    }
  };

  const checkSmsHandler = async (code: string) => {
    if (userData) {
      try {
        setIsSmsCheckLoading(true);
        registerAndCheck(userData, code);
      } catch (error) {
        handleError(error);
        return Promise.reject();
      } finally {
        setIsSmsCheckLoading(false);
      }
    }
  };

  const onGetCertificateHandler = async () => {
    if (!userData || isCertLoading) return;
    try {
      const cert = await getCertificate({ p: userData.pinfl }).unwrap();
      const url = URL.createObjectURL(dataURItoBlob(cert || ''));

      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank';

      a.click();
    } catch (err) {
      handleError(err);
    }
  };

  const registerAndCheck = async (
    userData: InvitationInfoResponse & {
      invitationCode: string;
    },
    code?: string
  ) => {
    let body = { invitationCode: userData.invitationCode };
    if (code) {
      Object.assign(body, { code });
    }

    const selfEmployedRegistered = await registerSelfEmployed(body)
      .unwrap()
      .then((res) => res.selfEmployedRegistered);

    if (selfEmployedRegistered) {
      await checkSelfEmployedStatus({
        p: userData.pinfl,
      }).unwrap();
    }
  };

  const renderContent = () => {
    if (isLoading || isRegLoading || isSmsLoading) {
      return <UILoader size={40} />;
    }

    if (data?.selfEmployed && userData) {
      return (
        <>
          <UIAlert type='success' text={t('self_empl_status.success')} />

          <Box
            mt={2.5}
            sx={{
              backgroundColor: Colors.LGRAY,
              px: 3,
              py: 2,
              borderRadius: '8px',
              width: '100%',
            }}
          >
            <Typography variant='subtitle2' fontWeight={500}>
              {userData.name}
            </Typography>

            <Typography variant='caption' color={Colors.BGRAY}>
              {t('pinfl')}: {userData.pinfl}
            </Typography>

            <Box
              component='div'
              onClick={onGetCertificateHandler}
              sx={{
                display: 'block',
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
            >
              <Typography variant='caption' color={Colors.ACCENT}>
                {t('cert_of_self_empl')} №{data?.docNum}
              </Typography>
            </Box>
          </Box>
        </>
      );
    }

    return <UIAlert type='warning' text={t('self_empl_status.error')} />;
  };

  return (
    <Container
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Stack
        alignItems='center'
        sx={{
          maxWidth: '400px',
          width: '100%',
        }}
      >
        <Typography variant='h5' mb={3}>
          {t('self_empl_status.title')}
        </Typography>

        {renderContent()}

        <UIButton
          disabled={!data?.selfEmployed && !cert}
          loading={isFinishLoading}
          onClick={onAcceptHandler}
          sx={{
            mt: 5,
          }}
        >
          {t('continue')}
        </UIButton>
      </Stack>

      <SmsConfirmModal
        ref={smsModalRef}
        blockClose
        loading={isSmsCheckLoading}
        reSendSms={async () => {
          if (!userData) return;
          await sendSms({ phone: userData.phone, pinfl: +userData.pinfl }).unwrap();
        }}
      />
    </Container>
  );
};
