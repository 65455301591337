import { FC } from 'react';
import {
  AppBar,
  Box,
  ButtonBase,
  ButtonBaseProps,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { LangSwitcher } from '@/features/lang-switcher';
import { useAppSelector } from '@/app/hooks';
import { getHeaderNavData } from './lib';
import { Link, useLocation } from 'react-router-dom';
import { ProfileMenu } from '@/widgets/profile-menu';
import { NavDrawer } from './ui/nav-drawer';
import { useModalRef } from '@/app/hooks/use-modal-ref';
import { TariffHeaderMenu } from '@/features/tariff-header-menu';

import LogoDidox from '@/app/assets/images/didox_s-logo.svg?react';
// import LogoutIcon from "@/app/assets/icons/logout.svg?react";
import BurgerMenu from '@/app/assets/icons/burger-menu.svg?react';

const ButtonComp: FC<ButtonBaseProps> = (props) => (
  <ButtonBase
    {...props}
    sx={{
      px: 2,
      height: '64px',
      ...props.sx,
    }}
  />
);

export const Header = () => {
  const { pathname } = useLocation();
  const { palette, breakpoints } = useTheme();
  const isLessThanLg = useMediaQuery(breakpoints.down('lg'));
  const isMobile = useMediaQuery('(max-width: 715px)');
  const { authorized, company, roles } = useAppSelector((state) => state.auth);
  const { cabinetType } = useAppSelector((state) => state.app);

  const navDrawerRef = useModalRef();

  const renderNav = () => {
    if (!authorized || !cabinetType || !company) return;

    const data = getHeaderNavData();

    return data.map((el, idx) => {
      const isActive = pathname === el.to;

      if (
        el.condition &&
        !el.condition({
          cabinetType,
          invitationFlow: company.invitationFlow,
          roles,
          tin: company.tin,
        })
      ) {
        return null;
      }

      return (
        <Box component='li' key={idx}>
          <Box
            {...('to' in el
              ? {
                  component: Link,
                  to: el.to,
                }
              : {})}
            sx={{
              color: palette.common.white,
              textDecoration: 'none',
              fontWeight: 600,
              px: 2.5,
              backgroundColor: isActive ? 'rgba(255,255,255,.1)' : 'transparent',
              borderRadius: '4px',
              display: 'block',
              lineHeight: '2',
            }}
          >
            {el.label}
          </Box>
        </Box>
      );
    });
  };

  return (
    <AppBar
      position='relative'
      color='secondary'
      sx={{
        pl: '1rem',
      }}
    >
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Stack direction='row' spacing={2} alignItems='center'>
          {isLessThanLg && (
            <ButtonBase
              onClick={() => {
                navDrawerRef.current?.open();
              }}
              sx={{
                p: 1,
                borderRadius: '50%',
              }}
            >
              <Box component={BurgerMenu} />
            </ButtonBase>
          )}
          <Stack component={Link} to='/' justifyContent='center'>
            <Box
              component={LogoDidox}
              sx={{
                width: isMobile ? '90px' : 'auto',
              }}
            />
          </Stack>
        </Stack>

        {!isLessThanLg && (
          <Stack
            direction='row'
            alignItems='center'
            component='ul'
            sx={{
              listStyle: 'none',
            }}
          >
            {renderNav()}
          </Stack>
        )}

        <Stack direction='row'>
          <LangSwitcher Container={ButtonComp} />

          {authorized && (
            <>
              {!isLessThanLg && cabinetType === 'customer' && <TariffHeaderMenu />}

              <ProfileMenu
                Container={ButtonComp}
                sx={{
                  backgroundColor: `rgba(255,255,255,.08)`,
                  maxWidth: '280px',
                  width: '100%',
                }}
              />

              {/* {!isYandex && (
                <ButtonComp
                  sx={{
                    backgroundColor: `rgba(255,255,255,.08)`,
                  }}
                  onClick={onLogoutHandler}
                >
                  <Box component={LogoutIcon} />
                </ButtonComp>
              )} */}
            </>
          )}
        </Stack>
      </Stack>

      <NavDrawer ref={navDrawerRef} />
    </AppBar>
  );
};
