import { UISection } from "@/shared/ui/ui-section";
import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import WalletIcon from "@/app/assets/icons/wallet.svg?react";
import CircleArrow from "@/app/assets/icons/circle-arrow-down.svg?react";
import { useTranslation } from "react-i18next";
import { Colors } from "@/app/constants/colors";
import { numericFormatter } from "react-number-format";
import { useAppSelector } from "@/app/hooks";
import { useCheckBalanceQuery } from "@/app/api";
import { UILoader } from "@/shared/ui/ui-loader";

export const BalanceWidgets = () => {
  const { t } = useTranslation();
  const { company } = useAppSelector((state) => state.auth);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { data, isLoading } = useCheckBalanceQuery(
    { p: company?.tin as string },
    { skip: !company?.tin }
  );

  return (
    <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap">
      <Box
        component={UISection}
        flex={1}
        sx={{
          px: 2,
          py: 1.5,
        }}
        flexBasis={isMobile ? "100%" : "auto"}
      >
        <Stack direction="row" spacing={1}>
          <WalletIcon />
          <Typography variant="body2" color={Colors.ACCENT}>
            {t("balance")}:
          </Typography>
        </Stack>

        {isLoading ? (
          <Box
            sx={{
              maxWidth: "fit-content",
              mt: 1.5,
            }}
          >
            <UILoader size={20} />
          </Box>
        ) : (
          <Typography variant="h6" mt={0.5}>
            {numericFormatter(
              String(((data?.availableBalance ?? 0) / 100).toFixed(2)),
              {
                thousandSeparator: " ",
              }
            )}{" "}
            <Box component="span" color={Colors.BGRAY}>
              UZS
            </Box>
          </Typography>
        )}
      </Box>

      <Box
        component={UISection}
        flex={1}
        sx={{
          px: 2,
          py: 1.5,
        }}
        flexBasis={isMobile ? "100%" : "auto"}
      >
        <Stack direction="row" spacing={1}>
          <CircleArrow />
          <Typography
            variant="body2"
            color={Colors.SUCCESS}
            sx={{
              whiteSpace: "nowrap",
            }}
          >
            {t("income_for_7_days")}:
          </Typography>
        </Stack>

        {isLoading ? (
          <Box
            sx={{
              maxWidth: "fit-content",
              mt: 1.5,
            }}
          >
            <UILoader size={20} />
          </Box>
        ) : (
          <Typography variant="h6" mt={0.5}>
            {numericFormatter(
              String(
                ((data?.incomeTransactionsFor7days ?? 0) / 100).toFixed(2)
              ),
              {
                thousandSeparator: " ",
              }
            )}{" "}
            <Box component="span" color={Colors.BGRAY}>
              UZS
            </Box>
          </Typography>
        )}
      </Box>

      <Box
        component={UISection}
        flex={1}
        sx={{
          px: 2,
          py: 1.5,
        }}
        flexBasis={isMobile ? "100%" : "auto"}
      >
        <Stack direction="row" spacing={1}>
          <Box
            component={CircleArrow}
            sx={{
              transform: "rotateX(180deg)",
              "& path": {
                fill: Colors.ERROR,
              },
            }}
          />
          <Typography variant="body2" color={Colors.ERROR}>
            {t("consumption_for_7_days")}:
          </Typography>
        </Stack>

        {isLoading ? (
          <Box
            sx={{
              maxWidth: "fit-content",
              mt: 1.5,
            }}
          >
            <UILoader size={20} />
          </Box>
        ) : (
          <Typography variant="h6" mt={0.5}>
            {numericFormatter(
              String(
                ((data?.expenseTransactionsFor7days ?? 0) / 100).toFixed(2)
              ),
              {
                thousandSeparator: " ",
              }
            )}{" "}
            <Box component="span" color={Colors.BGRAY}>
              UZS
            </Box>
          </Typography>
        )}
      </Box>
    </Stack>
  );
};
