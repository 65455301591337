import { useState } from 'react';
import { Layout } from '@/shared/layout';
import { FormControlLabel, Stack } from '@mui/material';
import { UISection } from '@/shared/ui/ui-section';
import { BalanceWidgets } from '@/widgets/balance-widgets';
import UITabs from '@/shared/ui/ui-tabs';
import { UISwitch } from '@/shared/ui/ui-switch';
import { BalanceInfoTable } from '@/features/balance-info-table';

import { useTranslation } from 'react-i18next';

export const BalancaPage = () => {
  const { t } = useTranslation();

  const [isTiins, setIsTiins] = useState(false);

  return (
    <Layout>
      <Stack
        spacing={2}
        sx={{
          height: '100%',
        }}
      >
        <BalanceWidgets />
        <UISection
          sx={{
            height: '100%',
            pt: 0,
          }}
        >
          <UITabs
            tabs={[
              {
                label: t('all'),
                component: <BalanceInfoTable isTiins={isTiins} />,
              },
              // {
              //   label: t("income"),
              //   component: <></>,
              // },
              // {
              //   label: t("consumption"),
              //   component: <></>,
              // },
            ]}
            endAdornment={
              <FormControlLabel
                sx={{
                  '& .MuiTypography-root': {
                    ml: 1,
                    fontSize: '1.6rem',
                    fontWeight: 600,
                  },
                }}
                control={<UISwitch onChange={(_, checked) => setIsTiins(checked)} />}
                label={t('sum_in_tiins')}
              />
            }
          />
        </UISection>
      </Stack>
    </Layout>
  );
};
