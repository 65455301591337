import { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import { Layout } from '@/shared/layout';
import { UISection } from '../../shared/ui/ui-section';
import UITabs from '../../shared/ui/ui-tabs';
import { Paper, PaperProps, Stack, StackProps, Typography } from '@mui/material';
import { TopSectionComponent } from './ui/top-section';
import { MiddleSectionComponent } from './ui/middle-section';
import { BottomSectionComponent } from './ui/bottom-section';
import { ReportsProvider } from './context';
import { UIAlert } from '@/shared/ui/ui-alert';
import {
  useEditYandexReportMutation,
  usePostYandexReportMutation,
  useLazyGetReportQuery,
  useLazyGetReportUklonQuery,
  usePostReportUklonMutation,
  useEditReportUklonMutation,
  useImportReportRowsFromAggregatorMutation,
} from '@/app/api';
import { useParams } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';
import { ClosingDocumentsComponent } from './ui/closing-documents';
import { UITable } from '../../shared/ui/ui-table';
import { UILoader } from '../../shared/ui/ui-loader';
import { useAppSelector } from '../../app/hooks';
import notify from '@/shared/toaster/lib/notify';
import dayjs from 'dayjs';
import _ from 'lodash';

import { useTranslation } from 'react-i18next';
import {
  ReportsPageFormValues,
  ChangeYandexRowModalFormValues,
  ReportsContextType,
  CustomYandexReportRowInterface,
} from './types';
import {
  EditUklonReport,
  EditYandexReport,
  EditYandexReportRow,
  GetYandexReportResponseBody,
  UklonReportRow,
} from '@/app/api/model';
import { useDidUpdateEffect } from '../../app/hooks/use-did-update-effect';

const DATE_FORMAT = 'DD.MM.YYYY';
const pollingInterval = 30000;

const initialValues: ReportsPageFormValues = {
  date: null,
  number: '',
  dateFrom: null,
  dateTo: null,
  contractNumber: '',
  contractDate: null,
  aggregatorPercent: 0,
  taxiPercent: 0,
  yandexReportRows: [],
};

const StackComponent = (props: PropsWithChildren<StackProps>) => {
  const { children, ...rest } = props;

  return (
    <Stack direction='row' {...rest}>
      {children}
    </Stack>
  );
};

const PaperComponent = (props: PropsWithChildren<PaperProps>) => {
  const { children, ...rest } = props;
  return (
    <Paper elevation={0} {...rest}>
      {children}
    </Paper>
  );
};

export const ReportTripsPage = () => {
  const { t } = useTranslation();
  const { unique } = useParams<{ unique: string }>();
  const { company } = useAppSelector((state) => state.auth);
  const [isPolling, setIsPolling] = useState<boolean>(false);

  const form = useForm<ReportsPageFormValues>({ defaultValues: initialValues, mode: 'onSubmit' });

  const { isUklon, isYandex } = useMemo(() => {
    const isUklon = company?.invitationFlow === 'uklon';
    const isYandex = company?.invitationFlow === 'yandex';

    return { isUklon, isYandex };
  }, [company]);

  const {
    fields: yandexReportRows,
    remove: removeYandexReportRow,
    update: updateYandexReportRow,
  } = useFieldArray({ control: form.control, name: 'yandexReportRows' });

  const {
    fields: uklonReportRows,
    remove: removeUklonReportRow,
    update: updateUklonReportRow,
  } = useFieldArray({ control: form.control, name: 'uklonReportRows' });

  const arrOfDeletedRows = useRef<{ unique: number; delete: boolean }[] | null>();
  const prevFormValues = useRef<Partial<EditYandexReport> | Partial<EditUklonReport> | null>(null);

  // * yandex report requests
  const [getYandexReport, { data: yandexReportData, isLoading: isYandexReportLoading }] =
    useLazyGetReportQuery({ pollingInterval: isPolling ? pollingInterval : undefined });
  const [
    postYandexReport,
    { isError: isYandexPostError, error: yandexPostError, isLoading: isYandexPostLoading },
  ] = usePostYandexReportMutation();
  const [
    editYandexReport,
    { isError: isYandexEditError, error: yandexEditError, isLoading: isYandexEditLoading },
  ] = useEditYandexReportMutation();
  const [importRowsFromAggregator, { isLoading: isImportFromAggregatorLoading }] =
    useImportReportRowsFromAggregatorMutation();

  // * uklon report requests
  const [getUklonReport, { data: uklonReportData, isLoading: isUklonReportLoading }] =
    useLazyGetReportUklonQuery();
  const [postUklonReport, { isLoading: isUklonPostLoading }] = usePostReportUklonMutation();
  const [editUklonReport, { isLoading: isUklonEditLoading }] = useEditReportUklonMutation();

  useEffect(() => {
    onMountGetReport();
  }, []);

  useDidUpdateEffect(() => {
    if (!isPolling) return;
    onMountGetReport();
  }, [isPolling, yandexReportData]);

  const onMountGetReport = async (page: number = 1) => {
    if (unique) {
      if (isYandex) {
        let responseData: GetYandexReportResponseBody | undefined;
        if (isPolling) {
          responseData = yandexReportData;
        } else {
          responseData = await getYandexReport({
            unique: +unique,
            page,
            pageSize: 10,
          }).unwrap();
        }

        const { dateFrom, dateTo, rows, date, contractDate, taxiPercent, aggregatorPercent } =
          responseData || {};

        const valuesToSet: ReportsPageFormValues = {
          ...responseData,
          dateFrom: dayjs(dateFrom, DATE_FORMAT),
          dateTo: dayjs(dateTo, DATE_FORMAT),
          contractDate: dayjs(contractDate, DATE_FORMAT),
          date: dayjs(date, DATE_FORMAT),
          //@ts-ignore
          yandexReportRows: rows.map((row) => ({
            ...row,
            ...(!!row.contractDate && { contractDate: dayjs(row.contractDate, DATE_FORMAT) }),
          })),
        };

        form.reset(valuesToSet);

        const { contractNumber, number } = valuesToSet;
        prevFormValues.current = {
          ...(contractDate && {
            contractDate: dayjs(contractDate, DATE_FORMAT).format(DATE_FORMAT),
          }),
          ...(contractNumber && { contractNumber }),
          ...(aggregatorPercent && { aggregatorPercent }),
          ...(taxiPercent && { taxiPercent }),
          number,
          dateFrom: dayjs(dateFrom, DATE_FORMAT).format(DATE_FORMAT),
          dateTo: dayjs(dateTo, DATE_FORMAT).format(DATE_FORMAT),
          date: dayjs(date, DATE_FORMAT).format(DATE_FORMAT),
          unique: +unique,
          rows: valuesToSet?.yandexReportRows?.map((row) => {
            const rowToSet: EditYandexReport['rows'][number] = {
              pinfl: row?.pinfl ?? '',
            };
            if (row.unique) {
              Object.assign(rowToSet, {
                unique: row.unique,
              });
            }
            if (responseData?.type.id === 'reward') {
              Object.assign(rowToSet, { taxiDriverReward: Number(row.taxiDriverReward) });
            }
            if (responseData?.type.id !== 'reward') {
              Object.assign(rowToSet, {
                amount: Number(row.amount),
                aggregatorPercent: Number(row.aggregatorPercent),
                taxiPercent: Number(row.taxiPercent),
              });
              if (row.contractNumber && row.contractDate) {
                Object.assign(rowToSet, {
                  contractNumber: row.contractNumber,
                  contractDate: dayjs(row.contractDate).format(DATE_FORMAT),
                });
              }
            }

            return rowToSet;
          }),
        };

        if (responseData?.status === 'posted') {
          if (responseData?.type.id !== 'travel') {
            if (!responseData?.documents?.length) {
              setIsPolling(true);
            } else setIsPolling(false);
          }

          if (responseData?.type.id === 'travel') {
            if (!responseData.rows.every((row) => row.documents?.length)) {
              setIsPolling(true);
            } else setIsPolling(false);
          }
        }
      }

      if (isUklon) {
        const uklonReportData = await getUklonReport({
          unique: +unique,
          pageSize: 10,
          page,
        }).unwrap();
        const { date, number, rows, dateFrom, dateTo } = uklonReportData;
        form.reset({
          date: dayjs(date, DATE_FORMAT),
          number,
          dateFrom: dayjs(dateFrom, DATE_FORMAT),
          dateTo: dayjs(dateTo, DATE_FORMAT),
          uklonReportRows: rows,
        });

        prevFormValues.current = {
          date: dayjs(date, DATE_FORMAT).format(DATE_FORMAT),
          dateFrom: dayjs(dateFrom, DATE_FORMAT).format(DATE_FORMAT),
          dateTo: dayjs(dateTo, DATE_FORMAT).format(DATE_FORMAT),
          number,
          rows,
          unique: +unique,
        };
      }
    }
  };

  const onClickPostReport = async (posted: boolean, check: boolean = false) => {
    const response = await form.trigger();
    if (!response) return;

    if (unique) {
      if (isYandex) {
        if (posted) {
          const values = form.getValues();
          const currentValues: EditYandexReport = formYandexReportRequestBody(+unique, values);

          if (!_.isEqual(prevFormValues.current, currentValues)) {
            Object.assign(values, { posted, check });
            return await onClickSaveReport(values);
          }
        }

        await postYandexReport({ unique: +unique, posted, check }).unwrap();
        await onMountGetReport();
      }

      if (isUklon) {
        if (posted) {
          const values = form.getValues();
          const currentValues: EditUklonReport = formUklonReportRequestBody(+unique, values);

          if (!_.isEqual(prevFormValues.current, currentValues)) {
            Object.assign(values, { posted, check });
            return await onClickSaveReport(values);
          }
        }

        await postUklonReport({ unique: +unique, posted, check }).unwrap();
        await onMountGetReport();
      }
    }
  };

  const onClickSaveReport = async (values: ReportsPageFormValues) => {
    if (unique) {
      if (isYandex) {
        if (!values.yandexReportRows?.length || !values.yandexReportRows?.every(Boolean)) {
          return notify(t('customer_trips_page.rows_are_empty'), 'error');
        }

        const body: EditYandexReport = formYandexReportRequestBody(+unique, values);
        if (arrOfDeletedRows.current && arrOfDeletedRows.current.length) {
          body['rows'] = [...body.rows, ...arrOfDeletedRows.current];
        }

        await editYandexReport(body).unwrap();
        await onMountGetReport();
      }

      if (isUklon) {
        if (!values.uklonReportRows?.length || !values.uklonReportRows?.every(Boolean)) {
          return notify(t('customer_trips_page.rows_are_empty'), 'error');
        }

        const body: EditUklonReport = formUklonReportRequestBody(+unique, values);
        if (arrOfDeletedRows.current && arrOfDeletedRows.current?.length) {
          //@ts-ignore
          body['rows'] = [...body.rows, ...arrOfDeletedRows.current];
        }

        await editUklonReport(body).unwrap();
        await onMountGetReport();
      }

      notify('', 'success');
      arrOfDeletedRows.current = null;
    }
  };

  const formYandexReportRequestBody = (
    unique: number,
    values: ReportsPageFormValues
  ): EditYandexReport => {
    const rows: EditYandexReportRow[] =
      values.yandexReportRows?.map((row, index) => {
        const rowToSet: EditYandexReportRow = {
          pinfl: row?.pinfl,
          ...((isRewardReport && { taxiDriverReward: Number(row.taxiDriverReward) }) || {}),
          ...((isTravelReport || isCorporateReport) && {
            amount: Number(row.amount),
            aggregatorPercent: Number(row.aggregatorPercent),
            taxiPercent: Number(row.taxiPercent),
            ...(row.contractNumber &&
              row.contractDate && {
                contractNumber: row.contractNumber,
                contractDate: dayjs(row.contractDate).format(DATE_FORMAT),
              }),
          }),
          ...(row.unique && { unique: row.unique }),
        };

        for (const key of Object.keys(rowToSet) as (keyof EditYandexReportRow)[]) {
          const prevRowValue = (prevFormValues.current?.rows?.[index] as EditYandexReportRow)?.[
              key
            ],
            rowValue = rowToSet[key];
          if (typeof prevRowValue !== 'undefined') {
            if (rowValue !== prevRowValue) {
              Object.assign(rowToSet, { edited: true });
              break;
            }
          }
        }

        return rowToSet;
      }) ?? [];

    return {
      unique,
      date: dayjs(values.date).format(DATE_FORMAT),
      number: values.number ?? '',
      dateFrom: dayjs(values.dateFrom).format(DATE_FORMAT),
      dateTo: dayjs(values.dateTo).format(DATE_FORMAT),
      rows,
      ...((isTravelReport || isCorporateReport) && {
        aggregatorPercent: Number(values.aggregatorPercent),
        taxiPercent: Number(values.taxiPercent),
      }),
      ...(!isTravelReport && {
        contractNumber: String(values.contractNumber),
        contractDate: dayjs(values.contractDate).format(DATE_FORMAT),
      }),
      ...(typeof values.posted !== 'undefined' && { posted: values.posted }),
      ...(typeof values.check !== 'undefined' && { check: values.check }),
    };
  };

  const formUklonReportRequestBody = (
    unique: number,
    values: ReportsPageFormValues
  ): EditUklonReport => {
    const rows =
      values.uklonReportRows?.map((row, index) => {
        const rowToSet: UklonReportRow = {
          ...row,
          brandingBonuses: row.brandingBonuses ? Number(row.brandingBonuses) : 0,
          cashPayment: row.cashPayment ? Number(row.cashPayment) : 0,
          compensations: row.compensations ? Number(row.compensations) : 0,
          dayProgramBonuses: row.dayProgramBonuses ? Number(row.dayProgramBonuses) : 0,
          driverWalletReplenishment: row.driverWalletReplenishment
            ? Number(row.driverWalletReplenishment)
            : 0,
          driverWalletTransfer: row.driverWalletTransfer ? Number(row.driverWalletTransfer) : 0,
          fines: row.fines ? Number(row.fines) : 0,
          orderBonuses: row.orderBonuses ? Number(row.orderBonuses) : 0,
          promoCodeCash: row.promoCodeCash ? Number(row.promoCodeCash) : 0,
          promoCodeNonCash: row.promoCodeNonCash ? Number(row.promoCodeNonCash) : 0,
          tipsNonCash: row.tipsNonCash ? Number(row.tipsNonCash) : 0,
          tripCostCash: row.tripCostCash ? Number(row.tripCostCash) : 0,
          tripCostNonCash: row.tripCostNonCash ? Number(row.tripCostNonCash) : 0,
          uklonCommissionCash: row.uklonCommissionCash ? Number(row.uklonCommissionCash) : 0,
          uklonCommissionNonCash: row.uklonCommissionNonCash
            ? Number(row.uklonCommissionNonCash)
            : 0,
          walletReplenishmentCommission: row.walletReplenishmentCommission
            ? Number(row.walletReplenishmentCommission)
            : 0,
          walletTransfer: row.walletTransfer ? Number(row.walletTransfer) : 0,
          withdrawalCommissionToDriverCard: row.withdrawalCommissionToDriverCard
            ? Number(row.withdrawalCommissionToDriverCard)
            : 0,
          withdrawalToDriverCard: row.withdrawalToDriverCard
            ? Number(row.withdrawalToDriverCard)
            : 0,
          pinfl: row?.pinfl,
          ...(row.unique && { unique: row.unique }),
          ...('edited' in row && { edited: row.edited }),
        };

        for (const key of Object.keys(rowToSet) as (keyof UklonReportRow)[]) {
          const prevRowValue = (prevFormValues.current?.rows?.[index] as UklonReportRow)?.[key],
            rowValue = rowToSet[key];
          if (typeof prevRowValue !== 'undefined') {
            if (rowValue !== prevRowValue) {
              Object.assign(rowToSet, { edited: true });
              break;
            }
          }
        }

        return rowToSet;
      }) ?? [];

    return {
      unique,
      rows,
      date: dayjs(values.date).format(DATE_FORMAT),
      number: values.number ?? '',
      dateFrom: dayjs(values.dateFrom).format(DATE_FORMAT),
      dateTo: dayjs(values.dateTo).format(DATE_FORMAT),
      ...(typeof values.posted !== 'undefined' && { posted: values.posted }),
      ...(typeof values.check !== 'undefined' && { check: values.check }),
    };
  };

  const onClickRemoveYandexRow = (index: number, unique: number | undefined) => {
    if (isYandex) removeYandexReportRow(index);
    if (isUklon) removeUklonReportRow(index);
    if (unique) {
      arrOfDeletedRows.current = [...(arrOfDeletedRows.current ?? []), { unique, delete: true }];
    }
  };

  const onClickUpdateYandexRow = (index: number, row: ChangeYandexRowModalFormValues) => {
    updateYandexReportRow(index, row);
  };

  const onClickUpdateUklonRow = (index: number, row: UklonReportRow) => {
    updateUklonReportRow(index, row);
  };

  const onClickImportRowsFromAggregator = async () => {
    if (typeof unique === 'undefined') return;
    const response = await importRowsFromAggregator({ unique: +unique })
      .unwrap()
      .then((res) => res.data);

    const editedResponseRows: CustomYandexReportRowInterface[] = response.map((obj) => {
      const rowToSet = { pinfl: obj.pinfl, name: obj.name };
      if (isTravelReport || isCorporateReport) {
        Object.assign(rowToSet, {
          amount: Number(obj.amount),
          aggregatorPercent: Number(obj.aggregatorPercent),
          taxiPercent: Number(obj.taxiPercent),
        });

        if (obj && obj.contractDate) {
          Object.assign(rowToSet, {
            contractNumber: obj.contractNumber,
            contractDate: dayjs(obj.contractDate).format('DD.MM.YYYY'),
          });
        }
      }

      return rowToSet;
    });

    const prevRows = form.getValues('yandexReportRows');
    const rowsToSet = [...(prevRows ?? []), ...editedResponseRows];
    form.setValue('yandexReportRows', rowsToSet);
    notify('', 'success');
  };

  const hasError = useMemo(() => {
    if (isYandexPostError && yandexPostError) {
      if ('data' in yandexPostError && yandexPostError.data.error.code == -809) {
        return true;
      }
    }

    if (isYandexEditError && yandexEditError) {
      if ('data' in yandexEditError && yandexEditError.data.error.code == -809) {
        return true;
      }
    }

    return false;
  }, [isYandexPostError, isYandexEditError]);

  const reportType = yandexReportData?.type.id;
  const reportTypeName =
    (isYandex ? yandexReportData?.type.name : t('commissioner_report') + ' Uklon') ?? '';
  const reportNumber = yandexReportData?.number ?? uklonReportData?.number ?? '';
  const reportDate = useMemo(
    () => dayjs((yandexReportData ?? uklonReportData)?.date, DATE_FORMAT).format(DATE_FORMAT),
    [yandexReportData?.date, uklonReportData?.date]
  );
  const reportStatus = yandexReportData?.status ?? uklonReportData?.status;
  const reportTitle = reportNumber.length
    ? t('document_title', {
        name: `${reportTypeName} №${reportNumber}`,
        date: reportDate,
      })
    : reportTypeName;

  const { isPosted, isRewardReport, isTravelReport, isCorporateReport, isDeclineReportDisabled } =
    useMemo(() => {
      const isPosted = reportStatus === 'posted';
      const isRewardReport = reportType === 'reward';
      const isTravelReport = reportType === 'travel';
      const isCorporateReport = reportType === 'corporate';
      const isDeclineReportDisabled =
        reportStatus === 'draft' ||
        !!yandexReportData?.documents?.some((doc) => doc.statusId === 'signed');

      return {
        isPosted,
        isRewardReport,
        isTravelReport,
        isCorporateReport,
        isDeclineReportDisabled,
      };
    }, [yandexReportData, uklonReportData]);

  const reportsHistory = useMemo(() => {
    const array: Record<string, string | null>[] = [];
    const entries = Object.entries((yandexReportData ?? uklonReportData)?.history ?? {});
    for (const [key, value] of entries) {
      array.push({ [key]: value });
    }

    return array;
  }, [yandexReportData, uklonReportData]);

  if (isYandexReportLoading || isUklonReportLoading) {
    return (
      <Layout>
        <UILoader />
      </Layout>
    );
  }

  const contextValue: ReportsContextType = {
    StackComponent,
    form,
    yandexReportData,
    uklonReportData,
    yandexReportRows,
    uklonReportRows,
    onClickRemoveYandexOrUklonRow: onClickRemoveYandexRow,
    onClickUpdateYandexRow,
    onClickUpdateUklonRow,
    onMountGetReport,
    closingDocumentOptions: yandexReportData?.closingDocuments,
    unique,
    isCreating: !!yandexReportData?.creating,
    isRewardReport,
    isTravelReport,
    isCorporateReport,
    isDeclineReportDisabled,
    isReportEditLoading: isYandexEditLoading || isUklonEditLoading,
    isReportPostLoading: isYandexPostLoading || isUklonPostLoading,
    isYandex,
    isUklon,
    title: reportTitle,
    onClickPostReport,
    arrOfClosingDocs: yandexReportData?.documents,
    isPosted,
    onClickImportRowsFromAggregator,
    isImportFromAggregatorLoading,
  };

  return (
    <ReportsProvider value={contextValue}>
      <Layout>
        <UITabs
          tabs={[
            {
              label: t('report'),
              component: (
                <Stack component='form' onSubmit={form.handleSubmit(onClickSaveReport)} spacing={2}>
                  <TopSectionComponent />

                  {hasError && (
                    <UIAlert
                      text={t('customer_trips_page.warning')}
                      sx={{ minWidth: '100%' }}
                      onClick={onClickImportRowsFromAggregator}
                    />
                  )}

                  <MiddleSectionComponent />
                  {!!(yandexReportRows.length || uklonReportRows.length) && (
                    <BottomSectionComponent />
                  )}
                  <ClosingDocumentsComponent />
                </Stack>
              ),
            },
            {
              label: t('history'),
              component: (
                <UISection>
                  <Typography variant='h5'>{t('history_of_changes')}</Typography>
                  <UITable
                    data={reportsHistory ?? []}
                    columns={[
                      {
                        label: t('yandex_docs_page.event'),
                        render: (row) => t(`yandex_docs_page.${Object.keys(row)[0]}`),
                      },
                      {
                        label: t('date'),
                        render: (row) => Object.values(row)[0],
                      },
                    ]}
                  />
                </UISection>
              ),
            },
          ]}
          TabsProps={{
            component: PaperComponent,
            sx: { flex: 1, p: 2, borderRadius: '8px' },
          }}
        />
      </Layout>
    </ReportsProvider>
  );
};
