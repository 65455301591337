import { Dispatch, FC, Fragment, SetStateAction, useMemo, useRef, useState } from 'react';
import {
  useGetCompanyWorkerStatusesQuery,
  useDeleteWorkersMutation,
  useSendInviteManuallyMutation,
} from '@/app/api';
import {
  DefaultResponseWithPagination,
  checkIfHasPermission,
  dataURItoBlob,
  getFirstLetters,
  handleError,
} from '@/app/helpers';
import { useAppSelector } from '@/app/hooks';
import {
  useLazyGetSelfEmployedCertificateQuery,
  useLazyGetSmsTextQuery,
} from '@/entities/auth/api';
import ConfirmModal from '@/shared/confirm-modal';
import { EmptyPlaceholder } from '@/shared/empty-placeholder';
import notify from '@/shared/toaster/lib/notify';
import { UIActionButton } from '@/shared/ui/ui-action-button';
import { UIChip } from '@/shared/ui/ui-chip';
import { UITable } from '@/shared/ui/ui-table';
import { Avatar, Box, ButtonBase, Stack, Tooltip, Typography } from '@mui/material';
import { UICopyText } from '@/shared/ui/ui-copy-text';
import PermissionCheckHOC from '../../../../shared/permission-hoc';

import TickCircle from '@/app/assets/icons/tick-circle.svg?react';
import CloseWarningIcon from '@/app/assets/icons/close-circle-warning.svg?react';
import ArrowRightIcon from '@/app/assets/icons/arrow-right.svg?react';

import { useTranslation } from 'react-i18next';
import { Employee, GetEmployeesFilterParams } from '@/app/api/model';
import { WorkerAssigmentContractStatus, WorkerStatus } from '@/app/constants';
import { Colors } from '@/app/constants/colors';
import { ConfirmModalRef } from '@/shared/confirm-modal/types';
import { AddEmployeeModalRef } from '@/shared/modals/add-employee-modal/types';
import { UIChipColor } from '@/shared/ui/ui-chip/types';
import { UITableColumn } from '@/shared/ui/ui-table/types';

interface Props {
  data: DefaultResponseWithPagination<Employee[]>;
  filterParams: GetEmployeesFilterParams;
  onChangeFilterParams: (params: Partial<GetEmployeesFilterParams>) => void;
  openAddEmployeeModal: AddEmployeeModalRef['open'];
  checkbox: {
    selected: number[];
    setSelected: Dispatch<SetStateAction<number[]>>;
  };
  isLoading: boolean;
}

export const EmployeesTable: FC<Props> = ({
  data,
  onChangeFilterParams,
  openAddEmployeeModal,
  checkbox,
  filterParams,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { company, selectedTaxiCompany, roles } = useAppSelector((state) => state.auth);
  const isTaxi = company?.invitationFlow === 'yandex' || company?.invitationFlow === 'uklon';
  const isTaxiAndDelivery = company?.invitationFlow === 'taxiAndDelivery';
  const isYandex = company?.invitationFlow === 'yandex';
  const isUzum = company?.invitationFlow === 'uzum';
  const isUklon = company?.invitationFlow === 'uklon';
  const isPlovme = company?.invitationFlow === 'plovme';

  const [resendLoading, setResetLoading] = useState<number[]>([]);
  const [certLoading, setCertLoading] = useState<number[]>([]);
  const [deleteLoading, setDeleteLoading] = useState<number[]>([]);
  const [getSmsLoading, setGetSmsLoading] = useState<number[]>([]);

  const [sendInvite] = useSendInviteManuallyMutation();
  const [getSelfEmplCert] = useLazyGetSelfEmployedCertificateQuery();
  const { data: statuses } = useGetCompanyWorkerStatusesQuery({});
  const [deleteWorkers] = useDeleteWorkersMutation();
  const [getSmsText] = useLazyGetSmsTextQuery();

  const confirmRef = useRef<ConfirmModalRef>(null);

  const getChipType = (
    type: WorkerStatus,
    label?: string
  ): {
    label: string;
    color: UIChipColor;
  } => {
    switch (type) {
      case WorkerStatus.ACTIVE:
        return {
          label: label ?? t('statuses.active'),
          color: 'success',
        };
      case WorkerStatus.NOT_INVITED:
        return {
          label: label ?? t('statuses.notInvited'),
          color: 'warning',
        };
      case WorkerStatus.INVITED:
        return {
          label: label ?? t('statuses.invited'),
          color: 'warning',
        };
      case WorkerStatus.DEACTIVATED:
        return {
          label: label ?? t('statuses.deactivated'),
          color: 'error',
        };
      default:
        return {
          label: label ?? t('statuses.canNotBeInvited'),
          color: 'error',
        };
    }
  };

  const resendInvitation = async (unique: number) => {
    try {
      setResetLoading((prev) => [...prev, unique]);
      await sendInvite({ unique }).unwrap();
      notify(t('invitation_sent_successfully'), 'success');
    } catch (error) {
      handleError(error);
    } finally {
      setResetLoading((prev) => prev.filter((item) => item !== unique));
    }
  };

  const getAssigmentChipColor = (status: WorkerAssigmentContractStatus): UIChipColor => {
    switch (status) {
      case WorkerAssigmentContractStatus.SIGNED:
        return 'success';
      case WorkerAssigmentContractStatus.CANCELED:
      case WorkerAssigmentContractStatus.REJECTED:
        return 'error';
      case WorkerAssigmentContractStatus.WAIT_CONTRAGENT_SIGN:
        return 'warning';
      default:
        return 'gray';
    }
  };

  const onOpenEmployeeCard = (data: Employee) => {
    openAddEmployeeModal(data);
  };

  const getCertificate = async (pinfl: string, idx: number) => {
    try {
      setCertLoading((prev) => [...prev, idx]);

      const cert = await getSelfEmplCert({ p: pinfl }).unwrap();
      const url = URL.createObjectURL(dataURItoBlob(cert || ''));

      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank';

      a.click();
    } catch (err) {
      handleError(err);
    } finally {
      setCertLoading((prev) => prev.filter((item) => item !== idx));
    }
  };

  const onDeleteWorkerHandler = async (unique: number) => {
    try {
      await confirmRef.current?.init({
        title: t('delete_employee_confirm_title'),
        text: '',
      });
      setDeleteLoading((prev) => [...prev, unique]);
      await deleteWorkers({ workers: [unique] }).unwrap();
      notify(t('employee_deleted_successfully'), 'success');
    } catch (error) {
      handleError(error);
    } finally {
      setDeleteLoading((prev) => prev.filter((item) => item !== unique));
    }
  };

  const onCopySmsTextHandler = async (unique: number) => {
    try {
      setGetSmsLoading((prev) => [...prev, unique]);
      const res = await getSmsText({ unique }).unwrap();
      navigator.clipboard.writeText(res.text);
      notify(t('sms_text_copied_successfully'), 'success');
    } catch (error) {
      handleError(error);
    } finally {
      setGetSmsLoading((prev) => prev.filter((item) => item !== unique));
    }
  };

  const renderStatusBarItem = (
    condition: boolean,
    text: string,
    endDate?: string,
    noIcon?: boolean,
    sx?: { color: string; fontSize: string; fontWeight: number }
  ) => {
    let color = sx?.color ?? Colors.SUCCESS;
    let Icon = TickCircle;

    if (!condition) {
      color = sx?.color ?? Colors.WARNING;
      Icon = CloseWarningIcon;
    }

    return (
      <Stack direction='row' alignItems='center' spacing={0.5}>
        {!noIcon && (
          <Box
            component={Icon}
            sx={{
              width: '2rem',
              height: '2rem',
            }}
          />
        )}

        <Typography
          variant='body2'
          color={color}
          fontWeight={sx?.fontWeight ?? 500}
          fontSize={sx?.fontSize}
        >
          {text}
          {condition && endDate && (
            <Box
              component='span'
              sx={{
                color: Colors.BGRAY,
                fontSize: '1.2rem',
              }}
            >
              {' '}
              {t('till_date', { date: endDate })}
            </Box>
          )}
        </Typography>
      </Stack>
    );
  };

  const hasPermission = useMemo(() => checkIfHasPermission(roles, ['18722']), [roles]);

  return (
    <>
      <UITable
        data={data?.data ?? []}
        checkbox={checkbox}
        isLoading={isLoading}
        addittionRows={({
          yandexSubcommitent,
          yourCommitent,
          status,
          selfEmployed,
          yourCommitentEndsAt,
          yandexSubcommitentEndsAt,
          uzumSubcommitent,
          uzumSubcommitentEndsAt,
          yandexDeliverySubcommitent,
          yandexDeliverySubcommitentEndsAt,
          selfEmployedDelivery,
          selfEmployedTaxi,
          unique,
          pinfl,
        }) => {
          return status === WorkerStatus.ACTIVE ? (
            <Stack spacing={0.5} direction='row' alignItems='center'>
              {renderStatusBarItem(true, t('registration'))}
              <Box component={ArrowRightIcon} />
              <Stack
                direction='row'
                onClick={
                  !!(selfEmployed || selfEmployedTaxi || selfEmployedDelivery) &&
                  pinfl &&
                  !certLoading.includes(unique)
                    ? () => getCertificate(pinfl, unique)
                    : undefined
                }
                spacing={0.5}
                sx={{ cursor: !!selfEmployed && pinfl ? 'pointer' : 'default' }}
              >
                {renderStatusBarItem(!!selfEmployed, t('self_employed'), undefined, true, {
                  color: Colors.BGRAY,
                  fontWeight: 400,
                  fontSize: '1.2rem',
                })}

                {isUklon &&
                  renderStatusBarItem(
                    !selfEmployed ? false : !!selfEmployedTaxi,
                    t('self_employed_taxi_status')
                  )}
                {(isUzum || isPlovme) &&
                  renderStatusBarItem(
                    !selfEmployed ? false : !!selfEmployedDelivery,
                    t('self_employed_delivery_status')
                  )}
                {isTaxiAndDelivery &&
                  ['taxi', 'delivery'].map((flow) => (
                    <Fragment key={flow}>
                      {renderStatusBarItem(
                        flow === 'taxi' ? !!selfEmployedTaxi : !!selfEmployedDelivery,
                        flow === 'taxi'
                          ? t('self_employed_taxi_status')
                          : t('self_employed_delivery_status')
                      )}
                    </Fragment>
                  ))}
                {isYandex &&
                  selectedTaxiCompany?.yandexSubFlows?.length &&
                  selectedTaxiCompany?.yandexSubFlows.map((flow) => (
                    <Fragment key={flow}>
                      {renderStatusBarItem(
                        flow === 'taxi' ? !!selfEmployedTaxi : !!selfEmployedDelivery,
                        flow === 'taxi'
                          ? t('self_employed_taxi_status')
                          : t('self_employed_delivery_status')
                      )}
                    </Fragment>
                  ))}
              </Stack>
              <Box component={ArrowRightIcon} />
              {renderStatusBarItem(!!yourCommitent, t('is_your_commitent'), yourCommitentEndsAt)}
              {isYandex && !!selectedTaxiCompany?.yandexSubFlows?.length && (
                <>
                  <Box component={ArrowRightIcon} />
                  {selectedTaxiCompany.yandexSubFlows.map((flow) => (
                    <Fragment key={flow}>
                      {renderStatusBarItem(
                        flow === 'delivery' ? !!yandexDeliverySubcommitent : !!yandexSubcommitent,
                        t(
                          flow === 'delivery'
                            ? 'is_yandex_eats_subcommitent'
                            : 'is_yandex_go_subcommitent'
                        ),
                        yandexDeliverySubcommitent
                          ? yandexDeliverySubcommitentEndsAt
                          : yandexSubcommitent
                          ? yandexSubcommitentEndsAt
                          : ''
                      )}
                    </Fragment>
                  ))}
                </>
              )}
              {isUzum && (
                <>
                  <Box component={ArrowRightIcon} />
                  {renderStatusBarItem(
                    !!uzumSubcommitent,
                    t('is_uzum_tezkor_subcommitent'),
                    uzumSubcommitentEndsAt
                  )}
                </>
              )}
            </Stack>
          ) : (
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              <Typography
                variant='body2'
                sx={{
                  color: Colors.BGRAY,
                }}
              >
                {t('invite_sent_to_employee')}
              </Typography>
              <Typography
                variant='body2'
                color={Colors.ACCENT}
                onClick={
                  getSmsLoading.includes(unique) ? undefined : () => onCopySmsTextHandler(unique)
                }
                sx={{
                  fontSize: '1.4rem',
                  fontWeight: 500,
                  whiteSpace: 'nowrap',
                  opacity: getSmsLoading.includes(unique) ? 0.5 : 1,
                  cursor: getSmsLoading.includes(unique) ? 'progress' : 'pointer',
                  backgroundColor: 'rgba(77, 55, 179, 0.16)',
                  px: 0.5,
                  py: 0.25,
                  borderRadius: '20px',
                  border: '1px solid transparent',
                }}
              >
                {t('copy_invite_sms_text')}
              </Typography>
            </Stack>
          );
        }}
        columns={[
          {
            label: t('photo'),
            render: (data) => (
              <ButtonBase
                sx={{
                  borderRadius: '50%',
                }}
                onClick={() => {
                  onOpenEmployeeCard(data);
                }}
              >
                <Avatar>{getFirstLetters(data.name)}</Avatar>
              </ButtonBase>
            ),
            nowrap: true,
          },
          {
            label: t('fio'),
            render: (data) => (
              <Box
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  onOpenEmployeeCard(data);
                }}
              >
                <Typography
                  variant='subtitle2'
                  fontWeight={500}
                  sx={{
                    ':hover': {
                      color: Colors.ACCENT,
                    },
                  }}
                >
                  {data.name}
                </Typography>
              </Box>
            ),
          },
          {
            label: t('self_employed_status'),
            render: ({ status }) => {
              const foundStatus = statuses?.data.find((el) => el.id === status);
              return <UIChip {...getChipType(status, foundStatus?.name)} />;
            },
          },
          {
            label: t('added_date'),
            key: 'createdAt',
          },
          ...(!isTaxi && !isPlovme
            ? ([
                {
                  label: t('agent_contract'),
                  render: ({ agentContractStatus, agentContractName }) =>
                    agentContractStatus ? (
                      <Stack spacing={0.5}>
                        <UIChip
                          label={t(`employee_assigment_status.${agentContractStatus}`)}
                          color={getAssigmentChipColor(agentContractStatus)}
                        />
                        <UIChip
                          label={agentContractName}
                          color={getAssigmentChipColor(agentContractStatus)}
                          noBGColor
                        />
                      </Stack>
                    ) : (
                      '-'
                    ),
                },
                {
                  label: t('assigment_contract'),
                  render: ({ assignmentContractStatus, assignmentContractName }) =>
                    assignmentContractStatus ? (
                      <Stack spacing={0.5}>
                        <UIChip
                          label={t(`employee_assigment_status.${assignmentContractStatus}`)}
                          color={getAssigmentChipColor(assignmentContractStatus)}
                        />
                        <UIChip
                          label={assignmentContractName}
                          color={getAssigmentChipColor(assignmentContractStatus)}
                          noBGColor
                        />
                      </Stack>
                    ) : (
                      '-'
                    ),
                },
                {
                  label: t('employee_card_number'),
                  render: ({ cardNumber, cardOwnerName }) => (
                    <Box>
                      <Typography variant='body2'>
                        {cardNumber?.slice(0, 4)} {cardNumber?.slice(4, 8)}{' '}
                        {cardNumber?.slice(8, 12)} {cardNumber?.slice(12, 16)}
                      </Typography>
                      <Typography color={Colors.BGRAY} variant='caption'>
                        {cardOwnerName}
                      </Typography>
                    </Box>
                  ),
                },
              ] as UITableColumn<Employee>[])
            : []),
          {
            label: t('pinfl'),
            render: ({ pinfl }) => (
              <UICopyText
                TypographyProps={{
                  variant: 'body2',
                }}
              >
                {pinfl}
              </UICopyText>
            ),
          },
          ...([
            data.data.some((el) => !!el?.excelImportError)
              ? {
                  label: t('import_error'),
                  key: 'excelImportError',
                }
              : {},
          ] as UITableColumn<Employee>[]),
          {
            render: ({ unique, pinfl, status, selfEmployed }) => (
              <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={0.5}>
                {pinfl && (
                  <Tooltip title={!hasPermission ? '' : t('download_self_empl_cert')}>
                    <Box component='span'>
                      <PermissionCheckHOC
                        WrappedComponent={UIActionButton}
                        props={{
                          icon: 'print',
                          loading: certLoading.includes(unique),
                          onClick: () => getCertificate(pinfl, unique),
                          disabled: !hasPermission,
                        }}
                      />
                    </Box>
                  </Tooltip>
                )}
                {status !== WorkerStatus.ACTIVE && (
                  <Tooltip title={!hasPermission ? '' : t('resend')}>
                    <Box component='span'>
                      <PermissionCheckHOC
                        WrappedComponent={UIActionButton}
                        props={{
                          icon: 'refresh',
                          loading: resendLoading.includes(unique),
                          onClick: () => resendInvitation(unique),
                          disabled: !hasPermission,
                        }}
                      />
                    </Box>
                  </Tooltip>
                )}
                {!selfEmployed && (
                  <Tooltip title={!hasPermission ? '' : t('delete_employee')}>
                    <Box component='span'>
                      <PermissionCheckHOC
                        WrappedComponent={UIActionButton}
                        props={{
                          icon: 'delete',
                          loading: deleteLoading.includes(unique),
                          onClick: () => onDeleteWorkerHandler(unique),
                          disabled: !hasPermission,
                        }}
                      />
                    </Box>
                  </Tooltip>
                )}
              </Stack>
            ),
            nowrap: true,
          },
        ]}
        EmptyPlaceholder={<EmptyPlaceholder />}
        PaginationProps={{
          page: filterParams?.page ?? 1,
          count: data?.totalPages ?? 0,
          onChange: (_e, page) => {
            onChangeFilterParams({
              page,
            });
          },
        }}
      />
      <ConfirmModal ref={confirmRef} />
    </>
  );
};
