import {
  FormEvent,
  Fragment,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  useCheckConfirmCodeMutation,
  useCheckSoliqQuery,
  useEditCompanyWorkerMutation,
  useGetCommissionersQuery,
  useGetRegionsQuery,
  useGetTaxiCompaniesQuery,
  useImportWorkersFromExcelWithQueueMutation,
  useInviteWorkerMutation,
  useLazyCheckCompanyWorkerExistsQuery,
  useLazyGetCardInfoQuery,
  useLazyGetCommissionersQuery,
  useLazyGetCompanyWorkerQuery,
  useLazyGetDistrictsQuery,
  useLazyGetInfoByTinOrPinflQuery,
  useLazyGetTaxiCompaniesQuery,
  useLazyGetVillagesQuery,
  useSendConfirmationCodeMutation,
  useSendInviteManuallyMutation,
} from '@/app/api';
import { checkIfHasPermission, dataURItoBlob, fileToBase64, handleError } from '@/app/helpers';
import { useAppSelector, useOpen } from '@/app/hooks';
import FormFormattedInput from '@/shared/inputs/form-formatted-input';
import { FormTextInput } from '@/shared/inputs/form-text-input';
import ModalTemplate from '@/shared/modal-template';
import { UIFormLabel } from '@/shared/ui/ui-form-label';
import {
  Box,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { SmsConfirmModal } from '../sms-confirm-modal';
import { UILoader } from '@/shared/ui/ui-loader';
import {
  useLazyCheckSelfEmployedStatusQuery,
  useLazyCheckWorkerPhoneInGNKQuery,
  useLazyGetSelfEmployedCertificateQuery,
} from '@/entities/auth/api';
import { UIAlert } from '@/shared/ui/ui-alert';
import FormAutocompleteInput from '@/shared/inputs/form-autocomplete-input';
import { UITable } from '../../ui/ui-table';
import { UIButton } from '../../ui/ui-button';
import UITabs from '@/shared/ui/ui-tabs';
import { AddEmployeeExcel } from './ui/add-employee-excel';
import PermissionCheckHOC from '../../permission-hoc';
import notify from '@/shared/toaster/lib/notify';
import _ from 'lodash';

import TickCircle from '@/app/assets/icons/tick-circle.svg?react';
import CloseWarningIcon from '@/app/assets/icons/close-circle-warning.svg?react';

import { useTranslation } from 'react-i18next';
import { SmsConfirmModalRef } from '../sms-confirm-modal/types';
import { BaseLocationData, Employee, InviteWorkerBody, TaxiCompany } from '@/app/api/model';
import { AddEmployeeModalRef } from './types';
import { Colors } from '@/app/constants/colors';
import { WorkerStatus } from '@/app/constants';

interface FormValues {
  pinfl: string;
  name: string;
  cardNumber: string;
  phone: string;
  addressRegister: string;
  addressResidential: string;
  commissioner?: {
    tin: string;
    name: string;
  };
  cardOwnerName?: string;
  taxiCompany: TaxiCompany;
  contactPerson?: string;
  village?: BaseLocationData;
  district?: BaseLocationData;
  region?: BaseLocationData;
}

const defaultValues = {
  pinfl: '',
  cardNumber: '',
  phone: '',
  name: '',
  addressRegister: '',
  addressResidential: '',
  cardOwnerName: '',
};

export const AddEmployeeModal = forwardRef<AddEmployeeModalRef>((_props, ref) => {
  const { t, language } = useTranslation()[1];
  const isMobile = useMediaQuery('(max-width:500px)');
  const { company, selectedTaxiCompany, roles } = useAppSelector((state) => state.auth);
  const isYandex = company?.invitationFlow === 'yandex';
  const isTaxi = company?.invitationFlow === 'yandex' || company?.invitationFlow === 'uklon';
  const isUzum = company?.invitationFlow === 'uzum';
  const isTaxiAndDelivery = company?.invitationFlow === 'taxiAndDelivery';
  const isUklon = company?.invitationFlow === 'uklon';
  const isPlovme = company?.invitationFlow === 'plovme';

  const { open, setOpen } = useOpen(false);
  const { control, handleSubmit, setValue, getValues, reset, watch, setError, clearErrors } =
    useForm<FormValues>({
      defaultValues,
    });

  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [smsLoading, setSmsLoading] = useState(false);
  const [editData, setEditData] = useState<Employee>();
  const [files, setFiles] = useState<File[]>([]);
  const [autoSend, setAutoSend] = useState(false);
  const [locations, setLocations] = useState<{ name: string; nameRu: string }[]>([]);
  const [history, setHistory] = useState<{ event: string; date: string | undefined }[]>();

  const [inviteWorker] = useInviteWorkerMutation();
  const [sendConfirmationCode] = useSendConfirmationCodeMutation();
  const [getCardInfo, { data: cardInfo, isError: isCardInfoError }] = useLazyGetCardInfoQuery();
  const [getInfoByPinfl, { originalArgs: pinflOriginalArgs, isError: isPinflError }] =
    useLazyGetInfoByTinOrPinflQuery();
  const [checkSelfEmplStatus, { isError: isCheckError }] = useLazyCheckSelfEmployedStatusQuery();
  const { data: isSoliqWorking, isFetching: isSoliqCheckFetching } = useCheckSoliqQuery(
    {},
    { skip: !open, refetchOnMountOrArgChange: true }
  );
  const [getCert, { isLoading: isCertLoading }] = useLazyGetSelfEmployedCertificateQuery();
  const { data: taxiCompanies, isLoading: isTaxiCompaniesLoading } = useGetTaxiCompaniesQuery(
    {},
    { skip: !isTaxi }
  );
  const [getTaxiCompanies] = useLazyGetTaxiCompaniesQuery();

  const [editEmployee, { isLoading: isEditLoading }] = useEditCompanyWorkerMutation();
  const [checkSms] = useCheckConfirmCodeMutation();
  const [getCompWorker] = useLazyGetCompanyWorkerQuery();
  const { data: commissioners } = useGetCommissionersQuery({}, { skip: !open || !isYandex });
  const [getComms] = useLazyGetCommissionersQuery();
  const [importWorkersFromExcel, { isLoading: isExcelImportLoading }] =
    useImportWorkersFromExcelWithQueueMutation();
  const [checkIsWorkerExists, { isFetching: isCheckPinflLoading }] =
    useLazyCheckCompanyWorkerExistsQuery();
  const [checkWorkerPhone, { isFetching: isCheckWorkerPhoneLoading }] =
    useLazyCheckWorkerPhoneInGNKQuery();
  const { data: regions, isLoading: isRegionsLoading } = useGetRegionsQuery({});
  const [getDistricts, { data: districts, isLoading: isDistrictsLoading }] =
    useLazyGetDistrictsQuery();
  const [getVillages, { data: villages, isLoading: isVillagesLoading }] = useLazyGetVillagesQuery();
  const [resendInvitation, { isLoading: isInvitationSending }] = useSendInviteManuallyMutation();

  const smsConfirmModalRef = useRef<SmsConfirmModalRef>(null);

  const gnkError =
    isPinflError || isCheckError || (!isSoliqWorking?.SoliqIsUp && !isSoliqCheckFetching);

  const watchedCard = watch('cardNumber') ?? '';
  const watchedName = watch('name') ?? '';

  useImperativeHandle(
    ref,
    () => ({
      open: onOpenHandler,
      close: onCloseHandler,
    }),
    []
  );

  useEffect(() => {
    if (!commissioners || !open) return;

    setValue(
      'commissioner',
      commissioners.data.find((el) => !!el?.default)
    );
  }, [commissioners, open]);

  const onOpenHandler = async (data?: Employee) => {
    if (data) {
      try {
        const { data: dataToSet } = await getCompWorker(data.unique).unwrap();
        reset({
          pinfl: dataToSet.pinfl ?? '',
          name: dataToSet.name ?? '',
          cardNumber: dataToSet.cardNumber ?? '',
          phone: (dataToSet.phone ?? '').replace(/\D/g, '').slice(3),
          addressRegister: dataToSet.addressRegister ?? '',
          addressResidential: dataToSet.addressResidential ?? '',
          contactPerson: dataToSet.contactPerson ?? '',
          ...(!!dataToSet?.subCommissioner && {
            commissioner: {
              tin: dataToSet?.subCommissioner,
              name: dataToSet?.subCommissionerName,
            },
          }),
        });
        setLocations([
          { name: dataToSet.regionName ?? '', nameRu: dataToSet.regionNameRu ?? '' },
          { name: dataToSet.districtName ?? '', nameRu: dataToSet.districtNameRu ?? '' },
          { name: dataToSet.villageName ?? '', nameRu: dataToSet.villageName ?? '' },
        ]);
        const historyToSet = [
          {
            event: t('employee_modal.created'),
            date: dataToSet.createdAt ?? '',
          },
          {
            event: t('employee_modal.invited'),
            date: dataToSet.invitedAt ?? '',
          },
          {
            event: t('employee_modal.active'),
            date: dataToSet.activeSince ?? '',
          },
        ];
        setHistory(historyToSet);

        if (isYandex && dataToSet?.subCommissioner) {
          getComms({}, true)
            .unwrap()
            .then((data) => {
              setValue(
                'commissioner',
                data.data.find((el) => el.tin === dataToSet.subCommissioner)
              );
            });
        }

        setEditData(dataToSet);
        setOpen(true);
        if (dataToSet.cardNumber) {
          getCardInfo({ cardNumber: dataToSet.cardNumber }).unwrap();
        }
      } catch (error) {
        handleError(error);
      }
    } else {
      setOpen(true);
    }
    if (isTaxi) {
      getTaxiCompanies({}, true).then((res) => {
        let selected = res.data?.data.find((el) => el.selected);
        if (!selected) {
          selected = res.data?.data.find((el) => el.isDefault);
        }

        if (selected) {
          setValue('taxiCompany', selected);
        }
      });
    }
  };

  const onCloseHandler = () => {
    setOpen(false);
    setTimeout(() => {
      reset(defaultValues);
      setEditData(undefined);
      setActiveTab(0);
      setAutoSend(false);
      setFiles([]);
    }, 300);
  };

  const onSubmitHandler = async (data: FormValues) => {
    const phone = '998' + data.phone.replace(/\D/g, '');
    try {
      setIsLoading(true);
      if (editData) {
        await editEmployee({
          unique: editData.unique,
          cardNumber: data.cardNumber?.replace(/\D/g, ''),
          addressRegister: data.addressRegister,
          addressResidential: data.addressResidential,
          cardOwnerName: data.cardOwnerName || '',
          taxiCompanyId: data.taxiCompany?.unique,
          phone,
          contactPerson: data.contactPerson,
          // ...(data.commissioner && {
          //   subCommissioner: data.commissioner.tin,
          //   subCommissionerName: data.commissioner.name,
          // }),
        }).unwrap();
      } else {
        const body: InviteWorkerBody = {
          pinfl: +data.pinfl,
          phone,
          name: data.name,
          cardNumber: data.cardNumber?.replace(/\D/g, ''),
          cardOwnerName: data.cardOwnerName || '',
          addressRegister: data.addressRegister,
          addressResidential: data.addressResidential,
          taxiCompanyId: data.taxiCompany?.unique,
          contactPerson: data.contactPerson,
          ...(data?.region?.code && {
            regionCode: data.region.code,
          }),
          ...(data?.district?.code && {
            districtCode: data.district.code,
          }),
          ...(data?.village?.code && {
            villageCode: data.village.code,
          }),
          // ...(data.commissioner && {
          //   subCommissioner: data.commissioner.tin,
          //   subCommissionerName: data.commissioner.name,
          // }),
        };

        if (!gnkError && !isTaxi) {
          const { selfEmployed } = await checkSelfEmplStatus({
            p: data.pinfl,
          }).unwrap();

          if (!selfEmployed) {
            await sendConfirmationCode({
              pinfl: +data.pinfl,
              phone,
            }).unwrap();
            const code = await smsConfirmModalRef.current
              ?.init(data.phone, async (code) => {
                await smsConfirmCallback(code, body);
              })
              .then((res) => res.code);
            body.selfEmployeeRegistrationCode = code;
            smsConfirmModalRef.current?.close();
          }
        }

        await inviteWorker(body).unwrap();
      }

      reset();
      notify('', 'success');
      onCloseHandler();
    } catch (err) {
      handleError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const smsConfirmCallback = async (code: string, body: InviteWorkerBody) => {
    try {
      setSmsLoading(true);
      await checkSms({
        pinfl: body.pinfl,
        code,
        phone: body.phone,
      }).unwrap();
    } finally {
      setSmsLoading(false);
    }
  };

  const getCardInfoHandler = async (value: string) => {
    const parsedValue = value.replace(/\D/g, '');

    if (parsedValue.length === 16) {
      try {
        const res = await getCardInfo({
          cardNumber: parsedValue,
        }).unwrap();
        setValue('cardOwnerName', res.data.name);
      } catch (error) {
        handleError(error);
      }
    }
  };

  const handlePinflData = async (pinfl: string) => {
    if (!pinfl.length) {
      return setValue('name', '');
    }
    if (pinfl.length !== 14) return;
    clearErrors('pinfl');

    const name = getValues('name');

    if (pinflOriginalArgs === pinfl && name) return;

    try {
      const taxiCompanyId = getValues('taxiCompany.unique');
      const { exists, message } = await checkIsWorkerExists({
        pinfl,
        taxiCompanyId,
      }).unwrap();
      if (exists && message) {
        setError(
          'pinfl',
          {
            message,
            type: 'onChange',
          },
          { shouldFocus: true }
        );
        setValue('name', '');
        return;
      }
      const data = await getInfoByPinfl(pinfl).unwrap();
      if (data.fullName === 'null null' || !data.fullName) {
        setError(
          'pinfl',
          {
            message: t('errors:incorrect_pinfl'),
            type: 'onChange',
          },
          { shouldFocus: true }
        );
        setValue('name', '');
      } else {
        setValue('name', data.fullName);
      }

      if (isUzum && data.address) setValue('addressRegister', data.address);
    } catch (error) {
      handleError(error);
    }
  };

  const onCertClickHandler = async () => {
    try {
      const res = await getCert({ p: editData?.pinfl ?? '' }).unwrap();
      const url = URL.createObjectURL(dataURItoBlob(res || ''));

      const link = document.createElement('a');
      link.target = '_blank';
      link.href = url;
      link.click();
    } catch (error) {
      handleError(error);
    }
  };

  const onExcelUploadSubmit = async (event: FormEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (!files.length) return;

    const base64 = await fileToBase64(files[0]).then((res) => res.split(',')[1]);

    try {
      await importWorkersFromExcel({
        base64,
        autoSendInvitations: autoSend || null,
        name: files[0].name,
      }).unwrap();
      notify('', 'success');
      onCloseHandler();
    } catch (error) {
      handleError(error);
    }
  };

  const onPhoneChangeHandler = async (value: string) => {
    const phone = value.replace(/\D/g, '');

    if (phone.length !== 9) return;
    clearErrors('phone');
    const pinfl = getValues('pinfl');
    if (!pinfl) return;
    const s = await checkSelfEmplStatus({ p: pinfl }, true).unwrap();

    if (!s.selfEmployed) {
      const wp = await checkWorkerPhone({ phone }, true).unwrap();
      if (wp.response) {
        setError('phone', {
          message: t('errors:phone_already_registered'),
        });
      }
    }
  };

  const onClickResendInvitation = async (unique: number) => {
    await resendInvitation({ unique }).unwrap();
    notify(t('invitation_sent_successfully'), 'success');
  };

  const renderStatusBarItem = (
    condition: boolean,
    text: string,
    endDate?: string,
    noIcon?: boolean,
    sx?: { color: string; fontSize: string; fontWeight: number }
  ) => {
    let color = sx?.color ?? Colors.SUCCESS;
    let Icon = TickCircle;

    if (!condition) {
      color = sx?.color ?? Colors.WARNING;
      Icon = CloseWarningIcon;
    }

    return (
      <Stack direction='row' alignItems='center' spacing={0.5}>
        {!noIcon && (
          <Box
            component={Icon}
            sx={{
              width: '2rem',
              height: '2rem',
            }}
          />
        )}

        <Typography
          variant='body2'
          color={color}
          fontWeight={sx?.fontWeight ?? 500}
          fontSize={sx?.fontSize}
        >
          {text}
          {condition && endDate && (
            <Box
              component='span'
              sx={{
                color: Colors.BGRAY,
                fontSize: '1.2rem',
              }}
            >
              {' '}
              {t('till_date', { date: endDate })}
            </Box>
          )}
        </Typography>
      </Stack>
    );
  };

  const isPinflAndPhoneEnabled = useMemo(() => {
    if (isTaxi || isUzum || isPlovme) {
      if (editData) {
        return [WorkerStatus.INVITED, WorkerStatus.NOT_INVITED, WorkerStatus.WRONG_DATA].includes(
          editData.status
        );
      } else return !editData;
    }

    return !editData;
  }, [editData, company]);

  const isCommissionerEnabled =
    !editData ||
    editData?.status === WorkerStatus.NOT_INVITED ||
    editData?.status === WorkerStatus.INVITED;

  const hasPermission = useMemo(() => checkIfHasPermission(roles, ['18722']), [roles]);

  const changeSubmitBtnText = () => {
    if (activeTab === 0) {
      return editData ? t('save') : t('send_invite');
    }

    if (!editData && activeTab === 1) {
      return t('upload_employees');
    }
  };

  const defineWhetherDisabled = () => {
    if (!editData && activeTab === 1) {
      return !files.length;
    }
    if (!hasPermission) return true;

    return false;
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          maxWidth: '735px',
          width: '100%',
          m: 1,
        },
      }}
    >
      <ModalTemplate
        onSubmit={
          !editData && activeTab === 1 ? onExcelUploadSubmit : handleSubmit(onSubmitHandler)
        }
        onClose={onCloseHandler}
        disabled={defineWhetherDisabled()}
        title={editData ? t('employee_card') : t('add_employees')}
        submitBtnText={changeSubmitBtnText()}
        loading={isLoading || isEditLoading || isExcelImportLoading}
        sx={{
          overflowX: 'hidden',
        }}
      >
        <UITabs
          // TabsProps={{
          //   sx: {
          //     ...((!isTaxi || editData) && {
          //       display: 'none',
          //     }),
          //   },
          // }}
          TabProps={{
            sx: {
              pt: 0,
            },
          }}
          TabPanelSx={{
            // ...((!isTaxi || editData) && {
            ...(editData && {
              py: 0,
            }),
          }}
          value={activeTab}
          onChange={(_event, newVal) => setActiveTab(newVal)}
          tabs={[
            {
              label: t('employee_modal.main_settings'),
              component: (
                <>
                  <Stack my={2} spacing={1.5}>
                    {gnkError && (
                      <UIAlert
                        text={t('gnk_error_enter_manually')}
                        sx={{
                          mb: 2,
                          maxWidth: '100%',
                        }}
                      />
                    )}
                    {editData &&
                      (() => {
                        const {
                          selfEmployed,
                          selfEmployedDelivery,
                          selfEmployedTaxi,
                          yandexDeliverySubcommitent,
                          yandexSubcommitent,
                          yandexDeliverySubcommitentEndsAt,
                          yandexSubcommitentEndsAt,
                          yourCommitent,
                          yourCommitentEndsAt,
                          uzumSubcommitent,
                          uzumSubcommitentEndsAt,
                          status,
                        } = editData;

                        return status === WorkerStatus.ACTIVE ? (
                          <Grid container spacing={1} alignItems='center'>
                            <Grid item xs={12} sm='auto'>
                              {renderStatusBarItem(true, t('registration'))}
                            </Grid>
                            <Grid item xs={12} sm='auto'>
                              {renderStatusBarItem(
                                !!selfEmployed,
                                t('self_employed'),
                                undefined,
                                true,
                                {
                                  color: Colors.BGRAY,
                                  fontWeight: 400,
                                  fontSize: '1.2rem',
                                }
                              )}
                            </Grid>
                            {isUklon && (
                              <Grid item xs={12} sm='auto'>
                                {renderStatusBarItem(
                                  !selfEmployed ? false : !!selfEmployedTaxi,
                                  t('self_employed_taxi_status')
                                )}
                              </Grid>
                            )}
                            {(isUzum || isPlovme) && (
                              <Grid item xs={12} sm='auto'>
                                {renderStatusBarItem(
                                  !selfEmployed ? false : !!selfEmployedDelivery,
                                  t('self_employed_delivery_status')
                                )}
                              </Grid>
                            )}
                            {isTaxiAndDelivery &&
                              ['taxi', 'delivery'].map((flow) => (
                                <Grid item xs={12} sm='auto' key={flow}>
                                  {renderStatusBarItem(
                                    flow === 'taxi' ? !!selfEmployedTaxi : !!selfEmployedDelivery,
                                    flow === 'taxi'
                                      ? t('self_employed_taxi_status')
                                      : t('self_employed_delivery_status')
                                  )}
                                </Grid>
                              ))}
                            {isYandex &&
                              selectedTaxiCompany?.yandexSubFlows?.length &&
                              selectedTaxiCompany.yandexSubFlows.map((flow) => (
                                <Grid item xs={12} sm='auto' key={flow}>
                                  {renderStatusBarItem(
                                    flow === 'taxi' ? !!selfEmployedTaxi : !!selfEmployedDelivery,
                                    flow === 'taxi'
                                      ? t('self_employed_taxi_status')
                                      : t('self_employed_delivery_status')
                                  )}
                                </Grid>
                              ))}
                            <Grid item xs={12} sm='auto'>
                              {renderStatusBarItem(
                                !!yourCommitent,
                                t('is_your_commitent'),
                                yourCommitentEndsAt
                              )}
                            </Grid>
                            {isYandex && !!selectedTaxiCompany?.yandexSubFlows?.length && (
                              <>
                                {selectedTaxiCompany.yandexSubFlows.map((flow) => (
                                  <Grid item xs={12} sm='auto' key={flow}>
                                    {renderStatusBarItem(
                                      flow === 'delivery'
                                        ? !!yandexDeliverySubcommitent
                                        : !!yandexSubcommitent,
                                      t(
                                        flow === 'delivery'
                                          ? 'is_yandex_eats_subcommitent'
                                          : 'is_yandex_go_subcommitent'
                                      ),
                                      yandexDeliverySubcommitent
                                        ? yandexDeliverySubcommitentEndsAt
                                        : yandexSubcommitent
                                        ? yandexSubcommitentEndsAt
                                        : ''
                                    )}
                                  </Grid>
                                ))}
                              </>
                            )}
                            {isUzum && (
                              <Grid item xs={12} sm='auto'>
                                {renderStatusBarItem(
                                  !!uzumSubcommitent,
                                  t('is_uzum_tezkor_subcommitent'),
                                  uzumSubcommitentEndsAt
                                )}
                              </Grid>
                            )}
                          </Grid>
                        ) : (
                          <Grid item xs={12} sm='auto'>
                            {renderStatusBarItem(
                              false,
                              t('invite_sent_to_employee'),
                              undefined,
                              true
                            )}
                          </Grid>
                        );
                      })()}
                    {editData && (
                      <Box
                        mb={2}
                        sx={{
                          backgroundColor: Colors.LGRAY,
                          px: 3,
                          py: 2,
                          borderRadius: '8px',
                          border: '1px solid rgba(77, 55, 179, 0.2)',
                          width: '100%',
                        }}
                      >
                        <Typography variant='subtitle2' fontWeight={500}>
                          {editData.name}
                        </Typography>

                        <Typography variant='caption' color={Colors.BGRAY}>
                          {t('pinfl')}: {editData.pinfl}
                        </Typography>
                        {editData?.certificateNumber && (
                          <Box component='div' onClick={onCertClickHandler}>
                            {isCertLoading ? (
                              <UILoader size={10} />
                            ) : (
                              <Typography
                                variant='caption'
                                color={Colors.ACCENT}
                                sx={{
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                }}
                              >
                                {t('cert_of_self_empl')} №{editData.certificateNumber}
                              </Typography>
                            )}
                          </Box>
                        )}
                      </Box>
                    )}
                  </Stack>
                  <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <Stack
                        spacing={1.5}
                        // sx={{
                        //   maxWidth: '320px',
                        //   width: '100%',
                        // }}
                      >
                        <FormControl required={!editData} disabled={!isPinflAndPhoneEnabled}>
                          <UIFormLabel>{t('pinfl')}:</UIFormLabel>
                          <FormFormattedInput
                            control={control}
                            name='pinfl'
                            format='##############'
                            disabled={!isPinflAndPhoneEnabled}
                            onChange={(event) => {
                              handlePinflData(event.target.value);
                            }}
                            mask=''
                            rules={{
                              required: !editData,
                            }}
                            InputProps={{
                              endAdornment: isCheckPinflLoading && <UILoader size={20} />,
                            }}
                          />
                        </FormControl>
                        {watchedName && (
                          <FormHelperText sx={{ color: Colors.ACCENT }}>
                            {watchedName}
                          </FormHelperText>
                        )}

                        {/* <FormControl required>
                          <UIFormLabel>{t('employee_fio')}:</UIFormLabel>

                          <FormTextInput
                            control={control}
                            name='name'
                            disabled={!gnkError}
                            rules={{
                              required: true,
                            }}
                            InputProps={{
                              endAdornment: isPinflFetching && <UILoader size={20} />,
                            }}
                          />
                        </FormControl> */}

                        <FormControl>
                          <UIFormLabel>{t('employee_card_number')}:</UIFormLabel>

                          <FormFormattedInput
                            control={control}
                            name='cardNumber'
                            format='#### #### #### ####'
                            onChange={({ target }) => {
                              getCardInfoHandler(target.value);
                            }}
                            mask=''
                          />
                          <FormHelperText>
                            {watchedCard.length === 19 && !isCardInfoError && cardInfo?.data?.name}
                          </FormHelperText>
                        </FormControl>

                        <FormControl required={!editData} disabled={!isPinflAndPhoneEnabled}>
                          <UIFormLabel>{t('phone_number')}:</UIFormLabel>

                          <FormFormattedInput
                            control={control}
                            name='phone'
                            disabled={!isPinflAndPhoneEnabled}
                            placeholder='(99) 999 99 99'
                            format='(##) ### ## ##'
                            onChange={({ target }) => onPhoneChangeHandler(target.value)}
                            InputProps={{
                              startAdornment: (
                                <Box
                                  sx={{
                                    mr: 1,
                                  }}
                                >
                                  +998
                                </Box>
                              ),
                              endAdornment: isCheckWorkerPhoneLoading && <UILoader size={20} />,
                            }}
                            rules={{
                              required: !editData,
                              validate: (value) => {
                                const parsedValue = (value as string).replace(/\D/g, '');
                                if (parsedValue.length !== 9) {
                                  return t('errors:phone_length');
                                }
                              },
                            }}
                          />
                          <FormHelperText>{t('confirm_number_helper_text')}</FormHelperText>
                        </FormControl>

                        {(!isTaxi || isUzum) && !isPlovme && (
                          <FormControl>
                            <UIFormLabel>{t('address_of_residence')}:</UIFormLabel>

                            <FormTextInput
                              control={control}
                              name='addressResidential'
                              multiline
                              sx={{
                                '& .MuiInputBase-inputMultiline': {
                                  minHeight: '80px',
                                },
                              }}
                            />
                          </FormControl>
                        )}

                        {isUzum && (
                          <FormControl>
                            <UIFormLabel>{t('relationship_contact')}:</UIFormLabel>

                            <FormTextInput
                              control={control}
                              name='contactPerson'
                              multiline
                              sx={{
                                '& .MuiInputBase-inputMultiline': {
                                  minHeight: '80px',
                                },
                              }}
                            />
                          </FormControl>
                        )}

                        {editData && editData.status !== WorkerStatus.ACTIVE && (
                          <PermissionCheckHOC
                            WrappedComponent={UIButton}
                            props={{
                              variant: 'outlined',
                              color: 'secondary',
                              onClick: () => onClickResendInvitation(editData.unique),
                              isMobile,
                              disabled: isInvitationSending || !hasPermission,
                              children: t('resend'),
                            }}
                          />
                        )}

                        {isTaxi && (
                          <FormControl disabled={!!editData}>
                            <UIFormLabel>{t('taxi_park')}:</UIFormLabel>

                            <FormAutocompleteInput
                              disabled={!!editData}
                              control={control}
                              name='taxiCompany'
                              options={taxiCompanies?.data ?? []}
                              getOptionLabel={(option) => option.name}
                              loading={isTaxiCompaniesLoading}
                              loadingText={<UILoader size={20} />}
                              disableClearable
                            />
                          </FormControl>
                        )}

                        {isYandex && (
                          <FormControl
                            required={!editData && !gnkError}
                            disabled={!isCommissionerEnabled || gnkError}
                          >
                            <UIFormLabel>{t('subcommittent_for')}:</UIFormLabel>

                            <Stack direction={isMobile ? 'column' : 'row'}>
                              {(_.uniqBy(commissioners?.data, 'tin') ?? []).map((obj, idx) => (
                                <Fragment key={idx}>
                                  <FormControl>
                                    <FormControlLabel
                                      control={<Checkbox checked={true} disabled />}
                                      label={+obj.tin === 310150022 ? 'Yandex Eats' : 'Yandex Go'}
                                    />
                                  </FormControl>
                                </Fragment>
                              ))}
                            </Stack>

                            {/* <FormAutocompleteInput
                          disabled={!isCommissionerEnabled}
                          control={control}
                          name='commissioner'
                          options={_.uniqBy(commissioners?.data, 'tin') ?? []}
                          getOptionLabel={(option) => option.name}
                          loading={isComsLoading}
                          loadingText={<UILoader size={20} />}
                          rules={{
                            required: !gnkError,
                          }}
                        /> */}
                          </FormControl>
                        )}
                      </Stack>

                      <SmsConfirmModal
                        ref={smsConfirmModalRef}
                        loading={smsLoading}
                        length={5}
                        reSendSms={async () => {
                          const data = getValues();

                          await sendConfirmationCode({
                            pinfl: +data.pinfl,
                            phone: data.phone.replace(/\D/g, ''),
                          }).unwrap();
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={1.5}>
                        {!editData ? (
                          <>
                            <FormControl>
                              <UIFormLabel>{t('region')}:</UIFormLabel>

                              <FormAutocompleteInput
                                control={control}
                                name='region'
                                options={regions?.data ?? []}
                                onChange={async (_, value) => {
                                  if (value) {
                                    await getDistricts({ region: value.code }).unwrap();
                                  }
                                }}
                                getOptionLabel={(option) =>
                                  language === 'ru' ? option.nameRu : option.name
                                }
                                loading={isRegionsLoading}
                                loadingText={<UILoader size={20} />}
                                disableClearable
                              />
                            </FormControl>

                            <FormControl disabled={!districts?.data.length}>
                              <UIFormLabel>{t('district')}:</UIFormLabel>

                              <FormAutocompleteInput
                                control={control}
                                name='district'
                                options={districts?.data ?? []}
                                onChange={async (_, value) => {
                                  if (value) {
                                    await getVillages({ district: value.code }).unwrap();
                                  }
                                }}
                                getOptionLabel={(option) =>
                                  language === 'ru' ? option.nameRu : option.name
                                }
                                loading={isDistrictsLoading}
                                loadingText={<UILoader size={20} />}
                                disableClearable
                              />
                            </FormControl>

                            <FormControl disabled={!villages?.data.length}>
                              <UIFormLabel>{t('village')}:</UIFormLabel>

                              <FormAutocompleteInput
                                control={control}
                                name='village'
                                options={villages?.data ?? []}
                                getOptionLabel={(option) => option.name}
                                loading={isVillagesLoading}
                                loadingText={<UILoader size={20} />}
                                disableClearable
                              />
                            </FormControl>
                          </>
                        ) : (
                          <Stack direction='column' spacing={1}>
                            {locations.map((location, index) => (
                              <Fragment key={index}>
                                <UIFormLabel>
                                  {index === 2
                                    ? t('village')
                                    : index === 1
                                    ? t('district')
                                    : t('region')}
                                  :
                                </UIFormLabel>
                                <TextField
                                  value={language === 'ru' ? location.nameRu : location.name}
                                  disabled
                                />
                              </Fragment>
                            ))}
                          </Stack>
                        )}

                        {(!isTaxi || isUzum) && !isPlovme && (
                          <FormControl>
                            <UIFormLabel>{t('address_of_registration')}:</UIFormLabel>

                            <FormTextInput
                              control={control}
                              name='addressRegister'
                              sx={{
                                '& .MuiInputBase-inputMultiline': {
                                  minHeight: '80px',
                                },
                              }}
                              multiline
                            />
                          </FormControl>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                </>
              ),
            },
            ...(editData
              ? [
                  {
                    label: t('employee_modal.history'),
                    component: (
                      <UITable
                        data={history ?? []}
                        columns={[
                          {
                            label: t('employee_modal.event'),
                            key: 'event',
                          },
                          {
                            label: t('date'),
                            key: 'date',
                          },
                        ]}
                      />
                    ),
                  },
                ]
              : []),
            // ...(!isTaxi || editData
            ...(editData
              ? []
              : [
                  {
                    label: t('import_from_file'),
                    component: (
                      <AddEmployeeExcel
                        files={files}
                        setFiles={setFiles}
                        checked={autoSend}
                        setChecked={setAutoSend}
                      />
                    ),
                  },
                ]),
          ]}
        />
      </ModalTemplate>
    </Dialog>
  );
});
