import { Box, Dialog, Stack, Typography } from "@mui/material";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { ConfirmModalInitArgs, ConfirmModalRef } from "./types";
import { useTranslation } from "react-i18next";
import { UIButton } from "../ui/ui-button";

const ConfirmModal = forwardRef<ConfirmModalRef>((_props, ref) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [btnColor, setBtnColor] = useState<ConfirmModalInitArgs["btnColor"]>();
  const [btnText, setBtnText] = useState<string>();
  const [text, setText] = useState<string>();
  const [title, setTitle] = useState<string>();
  const [loading, setLoading] = useState(false);

  const resolveCallback = useRef<() => void>();
  const rejectCallback = useRef<() => void>();

  useImperativeHandle(
    ref,
    () => ({
      init,
      close: handleClose,
      setLoading,
    }),
    []
  );

  const init: ConfirmModalRef["init"] = ({
    btnColor,
    btnText,
    title,
    text,
  }) => {
    return new Promise((resolve, reject) => {
      rejectCallback.current = reject;
      resolveCallback.current = resolve;

      setBtnColor(btnColor ?? "primary");
      setBtnText(btnText ?? t("confirm"));
      setTitle(title ?? t("confirm_action"));
      setText(text ?? t("confirm_action_text"));
      setOpen(true);
    });
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setText("");
      setTitle("");
      setBtnText("");
      setBtnColor(undefined);
    }, 500);
    // rejectCallback.current?.();
  };

  const handleCloseAndReject = () => {
    handleClose();
    rejectCallback.current?.();
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "600px",
          px: 2,
          py: 3,
        },
      }}
    >
      <Box>
        <Typography variant="h6" textAlign="center">
          {title ?? t("confirm_action")}
        </Typography>

        <Typography variant="body2" textAlign="center" sx={{ mt: 2 }}>
          {text ?? t("confirm_action_text")}
        </Typography>

        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          sx={{ mt: 2 }}
        >
          <UIButton
            color={btnColor ?? "error"}
            onClick={() => {
              handleCloseAndReject();
            }}
          >
            {t("cancel")}
          </UIButton>

          <UIButton
            loading={loading}
            color={btnColor ?? "primary"}
            onClick={() => {
              handleClose();
              resolveCallback.current?.();
            }}
          >
            {btnText ?? t("confirm")}
          </UIButton>
        </Stack>
      </Box>
    </Dialog>
  );
});

export default ConfirmModal;
